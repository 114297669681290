import React from 'react';

//Web3 patch notes (aka Blockchain notes included)
export default function PatchNotesWeb3() {
  return (
    <>
      <h1>Phantom Galaxies Version 5.0.18</h1>
      <p>Released 08/02/2024</p>
      <h2>Player Experience</h2>
      <ul>
        <li>Localisation v1 is now in. Select your language from the Options Menu. Some known issues and items still being worked on</li>
        <ul>
          <li>Some items are currently not translated, mainly some menu items, buttons as well as Bounty text.</li>
          <li>Some items won't display in their frames correctly and may spill out.</li>
          <li>Other playing, using characters not supported by your language will appear <code>&lt;?&gt;</code></li>
          <li>Some translations are inaccurate for the given context and can be confusing. Please report any inaccurate translations to our team if you find them in-game</li>
        </ul>
        <li>Updated Starfighter icons to help identify Starfighters.</li>
        <li>Updated Starfighter ability icons to reflect the actions more accurately.</li>
        <li>Added information screen for the Weapon and Equipment Perks in the tutorial menu.</li>
        <li>Decreased frequency of  loot drops and increased item sell values.</li>
      </ul>
      <h2>Fixes & Improvements</h2>
      <ul>
        <li>Added additional and improved save frequency.</li>
        <li>Updated Lancer running animations</li>
        <li>Health and Shield bars have been adjusted to display more accurate and timely information.</li>
        <li>Fixed an issue where the wrong message would be displayed when attempting to withdraw or deposit into a full stash or inventory.</li>
        <li>Improved missile VFX and spawn locations when fired</li>
        <li>Fixed an issue with Avatar Creator windows changing when entering a name that contains letters 'E', 'Q' and 'F'. 'G' and 'R' still being worked on.</li>
        <li>Removed a redundant UI where the quantity selector would show on individual items.</li>
        <li>Players can now scroll through the Avatar Selection menu if the list of owned Avatars is longer than the menu box.</li>
        <li>Audio updates. Another new banger in Shining Star.</li>
        <li>Improved User Experience when using the Mining Laser.</li>
        <ul>
          <li>Brought back the progress indicator</li>
          <li>Mining view now activates while mining</li>
          <li>Reduced time to swap to the Mining Laser</li>
        </ul>
      </ul>
      <h2>General Gameplay & Balancing</h2>
      <ul>
        <li>Adjusted the overall size of the leading target circle for locked on enemies in combat, leading to more explosive combat</li>
        <li>Adjusted maximum range Buster missiles can reach</li>
      </ul>
      <h3>Attributes</h3>
      <p>Attributes have gone through some extensive changes for this update.</p>
      <h4>Durability</h4>
      <p>Durability attributes have been modified to match the starfighter attribute rebalancing (see below). Otherwise they remain similar to their original values.</p>
      <h4>Shields</h4>
      <p>Shield attributes have been updated quite extensively. Previously a relatively small amount spent into the Shield attribute gained players an incorrect amount of benefit.</p>
      <p>The correct ranges and thresholds means that players will experience lower amounts shield health, extended shield regen delay and but also reduced the amount of time the shields will spend regenerating.</p>
      <h4>Maneuverability</h4>
      <p>Maneuverability attribute effectiveness has been flattened out across the board. This is to stop players from reach game breaking speeds and to equalize player movement. Along with these changes to maneuverability, mecha form speed has also been raised and adjusted to fix any discrepancies of mecha form speed between different starfighter classes.</p>
      <h4>Power</h4>
      <p>Power attribute curves have received quite extensive reworking to repair discrepancies in super ability cooldown times between different starfighters. Super ability cooldowns and heat dissipation now scale evenly across 100 points in Power.</p>
      <h4>Ordinance</h4>
      <p>Ordinance attribute balance has received a significant reworking to repair discrepancies in missile cooldown times between different starfighters. Missile cooldowns now scale evenly across 100 points in Ordinance.</p>
      <h4>Force</h4>
      <p>Force attribute curves have received extensive reworking. Tech cooldowns and Empowered Melee cooldowns now scale evenly across 100 points in Force.</p>
      <h3>Starfighters</h3>
      <p>For this update, the base attributes for each starfighter have also been updated. differences between each starfighter are clearly visible in their attributes although there is more detail we'll look to add in the future. These attribute values are specific starting points for each starfighter class.</p>
      <p>These values may change in the future as these attributes are further tuned and new features are implemented.</p>
      <h4>Assault</h4>
      <table>
        <tr>
          <th>Attribute Name</th>
          <th>Value</th>
        </tr>
        <tr>
          <td>Durability</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Shield</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Maneuverability</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Power</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Force</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Ordinance</td>
          <td>10</td>
        </tr>
      </table>
      <h4>Breacher</h4>
      <table>
        <tr>
          <th>Attribute Name</th>
          <th>Value</th>
        </tr>
        <tr>
          <td>Durability</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Shield</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Maneuverability</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Power</td>
          <td>15</td>
        </tr>
        <tr>
          <td>Force</td>
          <td>15</td>
        </tr>
        <tr>
          <td>Ordinance</td>
          <td>5</td>
        </tr>
      </table>
      <h4>Buster</h4>
      <table>
        <tr>
          <th>Attribute Name</th>
          <th>Value</th>
        </tr>
        <tr>
          <td>Durability</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Shield</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Maneuverability</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Power</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Force</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Ordinance</td>
          <td>5</td>
        </tr>
      </table>
      <h4>Lancer</h4>
      <table>
        <tr>
          <th>Attribute Name</th>
          <th>Value</th>
        </tr>
        <tr>
          <td>Durability</td>
          <td>0</td>
        </tr>
        <tr>
          <td>Shield</td>
          <td>0</td>
        </tr>
        <tr>
          <td>Maneuverability</td>
          <td>15</td>
        </tr>
        <tr>
          <td>Power</td>
          <td>15</td>
        </tr>
        <tr>
          <td>Force</td>
          <td>15</td>
        </tr>
        <tr>
          <td>Ordinance</td>
          <td>15</td>
        </tr>
      </table>
      <h2>Known Issues</h2>
      <ul>
        <li>In PVP there may be a desync between an enemy player's Health display and their actual health. Keep shooting, they are dying.</li>
        <li>Rolling with advanced roll can autocorrect Starfighter to horizon, this is not intended</li>
        <li>During the hacking sequences if the player keeps pressing the key to rotate a piece after the puzzle has been completed, the hacking screen will not close</li>
        <li>Game Over screens aren't working. After dying, the player will automatically respawn in the area. Which means for PVP there is no option to return to the menu or respawn at the Halberd-001 (you will need to leave the area manually).</li>
        <li>Unintentional AI behaviour during “Diplomatic Mission” where friendly AI will attack the player after all enemies are defeated</li>
        <li>The screen that displays unclaimed rewards from the previous ranger season in the Bounty terminal cannot be dismissed if the player presses the [ESC] key.</li>
      </ul>
    </>
  );
}
